import React, { Component } from 'react';
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleDown,
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import baseData from './data.json';
import Card from "react-bootstrap/Card";
import "./canvas.scss";
import CanvasCardContent from "./canvasCardContent";
import logo from './static/img/logo_white_simple.svg';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Form from "react-bootstrap/Form";
import {TextareaAutosize} from "react-autosize-textarea/lib/TextareaAutosize";

export default class Example extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: baseData
        }

    }

    componentDidMount() {
        this.setState({
            data: this.props.exampleData
        });
    }

    render() {
        return (
            <div>
                <Nav className="justify-content-between Canvas__header" activeKey={"/" + this.state.sessionId}>
                    <div className="d-flex flex-row justify-content-start">
                        <Nav.Item>
                            <Nav.Link href="/" className="Canvas__header-breadcrumb-item Canvas__logo-link">
                                <img className="Canvas__logo" src={logo} alt="Logo" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="Canvas__header-breadcrumb-item">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </Nav.Item>
                        <Nav.Item className="Canvas__header-breadcrumb-item">
                            Content Canvas
                        </Nav.Item>
                    </div>
                </Nav>
                <Container className="Canvas__content mt-3">
                    <div className="d-flex flex-wrap" id="contentCanvas" >
                        {
                            this.state.data.filter((value, index, self) =>
                                index === self.findIndex((v) => (
                                    v.column === value.column
                                ))
                            ).map((value, index) =>
                                value.column === "all-top" || value.column === "all-bottom" ?
                                    <div className={"d-flex w-100 column-" + value.column} key={"box-" + value.column}>
                                        { this.state.data.filter((columnValue) =>
                                            columnValue.column === value.column
                                        ).map((columnValue, index) =>
                                            <div className="w-100">
                                            <Card key={"card-"+columnValue.order} className="m-1 Canvas__card" style={{borderColor: columnValue.color, borderWidth: "2px"  }}>
                                                <Card.Header className="d-flex Canvas__card-header justify-content-between" style={{background: columnValue.color }}>
                                                    <div>{columnValue.order + ". " + columnValue.title}</div>
                                                </Card.Header>
                                                <Card.Body className="p-0 Canvas__card-body">
                                                    <Card.Text>
                                                        <CanvasCardContent disabled={true} cardContent={ columnValue.value } cardOrder={columnValue.order}  cardTitle={columnValue.title} cardPlaceholder={columnValue.cardPlaceholder}/>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                                { columnValue.decisions ?
                                                    <div key={"Card-Decision-"+columnValue.order} className={"mt-2 mb-2 align-self-end"} style={{ marginLeft: "4px", marginRight: "4px", borderColor: columnValue.color, borderWidth: "2px" }}>
                                                        <div className="p-0 Canvas__card-decision">
                                                            {columnValue.decisions.map((decision, index) =>
                                                                decision.type === "SINGLE" ?
                                                                    <div className="Canvas__card-decision-actions">
                                                                        <div key={decision.id + "-" + index}
                                                                             className="d-flex justify-content-between Canvas__card-decision-actions-action">
                                                                            <div
                                                                                className="Canvas__card-decision-actions-action-title">{decision.title}</div>
                                                                            <ButtonGroup toggle
                                                                                         className="Canvas__card-decision-actions-action-options"
                                                                                         size={"sm"}>
                                                                                <ToggleButton
                                                                                    type="radio"
                                                                                    variant="outline-secondary"
                                                                                    name="radio"
                                                                                    value={decision.positiveButtonText}
                                                                                    checked={decision.value === 1}
                                                                                >
                                                                                    {decision.positiveButtonText}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    type="radio"
                                                                                    variant="outline-secondary"
                                                                                    name="radio"
                                                                                    value={decision.negativeButtonText}
                                                                                    checked={decision.value === 0}
                                                                                >
                                                                                    {decision.negativeButtonText}
                                                                                </ToggleButton>
                                                                            </ButtonGroup>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    decision.type === "MULTI" ?
                                                                        <div
                                                                            className="Canvas__card-decision-actions">
                                                                            <div key={decision.id + "-" + index}
                                                                                 className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                                <div
                                                                                    className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                                <div>
                                                                                    <Form
                                                                                        className="Canvas__card-decision-actions-action-options w-100"
                                                                                        inline>
                                                                                        {
                                                                                            decision.options.map((option, index) =>
                                                                                                <Form.Check
                                                                                                    label={option}
                                                                                                    key={decision.id + "-check-" + index}
                                                                                                    id={option}
                                                                                                    inline
                                                                                                    checked={decision.value.indexOf(option) > -1}/>
                                                                                            )
                                                                                        }
                                                                                        {decision.hasOther ?
                                                                                            <div className="d-inline-flex">
                                                                                                <Form.Check
                                                                                                    label="Other: "
                                                                                                    id="Other"
                                                                                                    checked={decision.otherValue}/>
                                                                                                <input
                                                                                                    className="Canvas__card-decision-actions-action-input"
                                                                                                    placeholder="Type here..."
                                                                                                    value={decision.otherTextValue}
                                                                                                    disabled={!decision.otherValue}/>
                                                                                            </div>
                                                                                            : null }
                                                                                    </Form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            className="Canvas__card-decision-actions">
                                                                            <div key={decision.id + "-" + index}
                                                                                 className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                                <div
                                                                                    className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                                <TextareaAutosize className="Canvas__card-decision-actions-action-input"
                                                                                                  value={decision.value}
                                                                                                  placeholder="Type here..."/>

                                                                               </div>
                                                                        </div>
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        )}
                                    </div>
                                    :
                                    value.column === "arrow-top" || value.column === "arrow-bottom" ?
                                        <div className={"d-flex justify-content-center w-100 column-" + value.column} key={value.column}>
                                            <FontAwesomeIcon style={{ color: value.color }} icon={faAngleDown} size="2x"
                                            />
                                        </div>
                                        :
                                        <div className={"d-flex flex-column text-left justify-content-start Canvas__w-33 p-1 column-" + value.column}>
                                            { this.state.data.filter((columnValue, index, self) =>
                                                columnValue.column === value.column
                                            ).map((columnValue, index) =>
                                                <>
                                                <Card key={"Card-"+columnValue.order} className={"mt-2 w-100 align-self-end Canvas__card Canvas__card-" + columnValue.state } style={{borderColor: columnValue.color, borderWidth: "2px" }}>
                                                    <Card.Header className="d-flex Canvas__card-header justify-content-between" style={{background: columnValue.color }}>
                                                        <div>{columnValue.order + ". " + columnValue.title}</div>
                                                    </Card.Header>
                                                    <Card.Body className="p-0 Canvas__card-body">
                                                        <Card.Text>
                                                            <CanvasCardContent disabled={true} cardContent={ columnValue.value } cardOrder={columnValue.order} cardPlaceholder={columnValue.cardPlaceholder}/>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                { columnValue.decisions ?
                                                <div key={"Card-Decision-"+columnValue.order} className={"mt-2 w-100 align-self-end"} style={{borderColor: columnValue.color, borderWidth: "2px" }}>
                                                    <div className="p-0 Canvas__card-decision">
                                                        {columnValue.decisions.map((decision, index) =>
                                                            decision.type === "SINGLE" ?
                                                                <div className="Canvas__card-decision-actions">
                                                                    <div key={decision.id + "-" + index}
                                                                         className="d-flex justify-content-between Canvas__card-decision-actions-action">
                                                                        <div
                                                                            className="Canvas__card-decision-actions-action-title">{decision.title}</div>
                                                                        <ButtonGroup toggle
                                                                                     className="Canvas__card-decision-actions-action-options"
                                                                                     size={"sm"}>
                                                                            <ToggleButton
                                                                                type="radio"
                                                                                variant="outline-secondary"
                                                                                name="radio"
                                                                                value={decision.positiveButtonText}
                                                                                checked={decision.value === 1}
                                                                            >
                                                                                {decision.positiveButtonText}
                                                                            </ToggleButton>
                                                                            <ToggleButton
                                                                                type="radio"
                                                                                variant="outline-secondary"
                                                                                name="radio"
                                                                                value={decision.negativeButtonText}
                                                                                checked={decision.value === 0}
                                                                            >
                                                                                {decision.negativeButtonText}
                                                                            </ToggleButton>
                                                                        </ButtonGroup>
                                                                    </div>
                                                                </div>
                                                                :
                                                                decision.type === "MULTI" ?
                                                                    <div
                                                                        className="Canvas__card-decision-actions">
                                                                        <div key={decision.id + "-" + index}
                                                                             className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                            <div
                                                                                className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                            <div>
                                                                                <Form
                                                                                    className="Canvas__card-decision-actions-action-options w-100"
                                                                                    inline>
                                                                                    {
                                                                                        decision.options.map((option, index) =>
                                                                                            <Form.Check
                                                                                                label={option}
                                                                                                key={decision.id + "-check-" + index}
                                                                                                id={option}
                                                                                                inline
                                                                                                checked={decision.value.indexOf(option) > -1}/>
                                                                                        )
                                                                                    }
                                                                                    {decision.hasOther ?
                                                                                    <div className="d-inline-flex">
                                                                                        <Form.Check
                                                                                            label="Other: "
                                                                                            id="Other"
                                                                                            checked={decision.otherValue}/>
                                                                                        <input
                                                                                            className="Canvas__card-decision-actions-action-input"
                                                                                            placeholder="Type here..."
                                                                                            value={decision.otherTextValue}
                                                                                            disabled={!decision.otherValue}/>
                                                                                    </div>
                                                                                        : null }
                                                                                </Form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        className="Canvas__card-decision-actions">
                                                                        <div key={decision.id + "-" + index}
                                                                             className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                            <div
                                                                                className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                            <TextareaAutosize className="Canvas__card-decision-actions-action-input"
                                                                                              value={decision.value}
                                                                                              placeholder="Type here..."/>
                                                                        </div>
                                                                    </div>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                                    :
                                                    null
                                                }
                                                </>
                                            )}
                                        </div>
                            )}
                    </div>
                </Container>
                <div className="mt-5 pt-3 mb-3" style={{ borderTop: "1px solid #969696"}}>
                    <a href="https://hhlabc.de/impressum">Imprint</a>
                    &nbsp;|&nbsp;
                    <a href="https://hhlabc.de/datenschutz/">Privacy</a>
                </div>
            </div>
        );
    }
}