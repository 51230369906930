import React, {Component} from "react";
import { FormControl, InputGroup, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Link} from "react-router-dom";

export default class SharePopover extends Component {
    constructor(props) {
        super(props);

        this.state = {
          showModal: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
           showModal: true
        });
    }

    hideModal() {
        this.setState({
            showModal: false
        });
    }

    copyToClipboard(e) {
        navigator.clipboard.writeText(window.location.href);
    };

    render() {
        return (
            <>
                <OverlayTrigger
                    key="tooltip-open"
                    placement="bottom"
                    overlay={
                        <Tooltip id={`tooltip-open`}>
                            Share this content canvas
                        </Tooltip>
                    }
                >
                    <Link className="nav-link separator-right" onClick={this.showModal}>Share</Link>
                </OverlayTrigger>
                <Modal show={this.state.showModal} onHide={this.hideModal} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Share</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Copy and send this link to your colleague to work together with them.
                        <InputGroup className="mb-3">
                            <FormControl
                                value={window.location.href}
                                disabled
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={this.copyToClipboard}>
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Modal.Body>
                </Modal>
            </>);
    }
}