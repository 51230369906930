import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import './App.css';
import Canvas from "./canvas";
import Home from "./home";
import history from './history';
import Example from "./example";
import bikingData from './example-biking-data.json';
import cookingData from './example-cooking-data.json';

function App() {
  return (
    <div className="App">
        <Router history={history}>
          <Switch>
              <Route path="/example/biking">
                  <Example exampleData={bikingData} />
              </Route>
              <Route path="/example/cooking">
                  <Example exampleData={cookingData} />
              </Route>
              <Route path="/:sessionId">
                  <Canvas />
              </Route>
              <Route path="/">
                  <Home />
              </Route>
          </Switch>
        </Router>
    </div>
  );
}

export default App;
