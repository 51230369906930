import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Link} from "react-router-dom";

export default class ExamplePopover extends Component {
    constructor(props) {
        super(props);

        this.state = {
          showModal: false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({
           showModal: true
        });
    }

    hideModal() {
        this.setState({
            showModal: false
        });
    }

    render() {
        return (
            <>
                <OverlayTrigger
                    key="tooltip-open"
                    placement="bottom"
                    overlay={
                        <Tooltip id={`tooltip-open`}>
                            Take a look at some examples.
                        </Tooltip>
                    }
                >
                    <Link className="nav-link" onClick={this.showModal}>Examples</Link>
                </OverlayTrigger>
                <Modal show={this.state.showModal} onHide={this.hideModal} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Examples</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Examples for filling in the content canvas.</p>
                        <ul>
                            <li>
                                <Link to={"/example/biking"} target={"_blank"}>Journalistic bicycle channel</Link>
                            </li>
                            <li>
                                <Link to={"/example/cooking"} target={"_blank"}>Comedy cooking channel</Link>
                            </li>
                        </ul>
                    </Modal.Body>
                </Modal>
            </>);
    }
}