import React, {Component} from "react";
import './canvasCardContent.scss';
import {TextareaAutosize} from "react-autosize-textarea/lib/TextareaAutosize";


export default class CanvasCardContent extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        if(!this.props.disabled) {
            this.props.changeContent(e.target.value, this.props.cardOrder);
        }
    }

    render() {
        return (
            <TextareaAutosize disabled={this.props.disabled} placeholder={this.props.cardPlaceholder} className="cardContent__textarea" value={this.props.cardContent} onChange={this.handleChange}  />
        );
    }
}