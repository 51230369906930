import * as Y from 'yjs';
import { WebsocketProvider } from 'y-websocket';
import {IndexeddbPersistence} from "y-indexeddb";

const document = new Y.Doc();
const permanentUserData = new Y.PermanentUserData(document);

export default class Collaboration {

    connect(sessionId) {
        const websocketAddress = "wss://" + window.location.hostname + "/websocket"
        let username = localStorage.getItem("username");

        if(!username) {
            username = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
            localStorage.setItem("username", username);
        }
        permanentUserData.setUserMapping(document, document.clientID, username);

        const isConnected = new WebsocketProvider(
            websocketAddress, sessionId, document
        );
        console.log(isConnected);

        const indexeddbProvider = new IndexeddbPersistence(sessionId, document)
        indexeddbProvider.whenSynced.then(() => {
            console.log('loaded data from indexed db');
        })

        return isConnected;
    }

    getData() {
        return document.getMap("data");
    }

    pushData(data, newData) {
        data.set("data", JSON.stringify(newData));
        console.log(data);
    }

    getDocument(){
        return document;
    }

    getUserData() {
        return permanentUserData;
    }
}