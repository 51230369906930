import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { saveAs } from 'file-saver';
import {
    faAngleDown,
    faAngleRight,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import sessionIdHelper from "./sessionIdHelper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import baseData from './data.json';
import Card from "react-bootstrap/Card";
import "./canvas.scss";
import CanvasCardContent from "./canvasCardContent";
import Modal from "react-bootstrap/Modal";
import Tooltip from "react-bootstrap/Tooltip";
import domtoimage from 'dom-to-image';
import Collaboration from "./collaboration";
import SharePopover from "./sharePopover";
import logo from './static/img/logo_white_simple.svg';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {TextareaAutosize} from "react-autosize-textarea/lib/TextareaAutosize";
import ReactMarkdown from 'react-markdown';
import ExamplePopover from "./examplePopover";
const collaboration = new Collaboration();
let wsData;

class Canvas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionId: "",
            data: baseData,
            showHelp: false,
            chosenCardTitle: "",
            chosenCardHelp: "",
            showModal: false,
            modalText: "",
            modalTitle: ""
        }

        this.downloadData = this.downloadData.bind(this);
        this.uploadData = this.uploadData.bind(this);
        this.downloadImage = this.downloadImage.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onChangeCard = this.onChangeCard.bind(this);
        this.toggleHelpModal = this.toggleHelpModal.bind(this);
        this.checkButton = this.checkButton.bind(this);
        this.changeOption = this.changeOption.bind(this);
        this.changeDecisionText = this.changeDecisionText.bind(this);
        this.changeOtherText = this.changeOtherText.bind(this);
        this.changeOther = this.changeOther.bind(this);
    }

    componentDidMount() {
        const sessionId = this.props.match.params.sessionId;

        if(sessionId) {
            const isValid = sessionIdHelper.checkSessionId(sessionId);

            if(isValid) {
                this.setState({ sessionId: sessionId });
            } else {
                this.props.history.push("/");
            }
        }else {
            console.error("No session id.");
        }

        this.fileSelector = this.buildFileSelector();

        collaboration.connect(sessionId);

        wsData = collaboration.getData()
        wsData.observe(event => {
            const newData = wsData.get("data");
            this.setState({
                data: JSON.parse(newData),
            });
        });
    }

    downloadData() {
        const fileName = 'content-canvas-' + (new Date()).getTime() + '.json'
        const fileToSave = new Blob([JSON.stringify(this.state.data)], {
            type: 'application/json',
            name: fileName
        });

        saveAs(fileToSave, fileName);
    }

    uploadData(e) {
        e.preventDefault();
        this.fileSelector.click();
    }

    buildFileSelector(){
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('multiple', 'multiple');
        fileSelector.onchange = this.onChangeFile;
        return fileSelector;
    }

    downloadImage() {
        domtoimage.toBlob(document.getElementById('contentCanvas'), { bgcolor: "white", filter:function(node){
                if(node.nodeType===1 && node.tagName==="INPUT" &&
                    (""+node.type).toLowerCase() === "checkbox"){
                    if(node.checked){
                        node.setAttribute('checked', true);
                    }else{
                        node.removeAttribute('checked');
                    }
                }
                return true;
            }} )
            .then(function (blob) {
                window.saveAs(blob, 'content-canvas.png');
            });
    }

    onChangeFile(e) {
        e.preventDefault();
        let file = e.target.files[0];
        const fileReader = new FileReader();

        fileReader.onload = () => {
            this.setState({data: JSON.parse(fileReader.result.toString())});
            collaboration.pushData(wsData, JSON.parse(fileReader.result.toString()));
        }
        fileReader.readAsText(file);

    }

    onChangeCard(value, order) {
        const data = this.state.data.map(item => {
            if(item.order === order) {
                item.value = value;
            }

            return item;
        });

        this.setState({ data: data });
        collaboration.pushData(wsData, data);
    }

    render() {
        return (
            <div>
                <Nav className="justify-content-between Canvas__header" activeKey={"/" + this.state.sessionId}>
                    <div className="d-flex flex-row justify-content-start">
                        <Nav.Item>
                            <Nav.Link href="/" className="Canvas__header-breadcrumb-item Canvas__logo-link">
                                <img className="Canvas__logo" src={logo} alt="Logo" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="Canvas__header-breadcrumb-item">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </Nav.Item>
                        <Nav.Item className="Canvas__header-breadcrumb-item">
                            Content Canvas
                        </Nav.Item>
                    </div>
                    <div className="d-flex flex-row justify-content-end">
                        <Nav.Item>
                                <SharePopover sessionId={this.state.sessionId} />
                        </Nav.Item>
                        <Nav.Item>

                                <OverlayTrigger
                                    key="tooltip-open"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-open`}>
                                            Load content canvas from file.
                                        </Tooltip>
                                    }
                                >
                                    <Nav.Link href="#" className="separator-right" onClick={this.uploadData}>Open</Nav.Link>
                                </OverlayTrigger>
                        </Nav.Item>
                        <Nav.Item>
                                <OverlayTrigger
                                    key="tooltip-open"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-open`}>
                                            Save content canvas to file.
                                        </Tooltip>
                                    }
                                >
                                    <Nav.Link className="separator-right" href="#" onClick={this.downloadData}>Save</Nav.Link>
                                </OverlayTrigger>
                        </Nav.Item>
                        <Nav.Item>
                                <OverlayTrigger
                                    key="tooltip-open"
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id={`tooltip-open`}>
                                            Export content canvas to image
                                        </Tooltip>
                                    }
                                >
                                    <Nav.Link className="separator-right" href="#" onClick={this.downloadImage}>Export</Nav.Link>
                                </OverlayTrigger>
                        </Nav.Item>
                        <Nav.Item>
                            <ExamplePopover />
                        </Nav.Item>
                    </div>
                </Nav>
                <Container className="Canvas__content mt-3">
                    <div className="d-flex flex-wrap" id="contentCanvas" >
                        {
                            this.state.data.filter((value, index, self) =>
                                index === self.findIndex((v) => (
                                    v.column === value.column
                                ))
                            ).map((value, index) =>
                                value.column === "all-top" || value.column === "all-bottom" ?
                                    <div className={"d-flex w-100 column-" + value.column} key={"box-" + value.column}>
                                        { this.state.data.filter((columnValue) =>
                                            columnValue.column === value.column
                                        ).map((columnValue, index) =>
                                            <div className="w-100">
                                            <Card key={"card-"+columnValue.order} className="m-1 Canvas__card" style={{borderColor: columnValue.color, borderWidth: "2px"  }}>
                                                <Card.Header className="d-flex Canvas__card-header justify-content-between" style={{background: columnValue.color }}>
                                                    <div>{columnValue.order + ". " + columnValue.title}</div>
                                                    <FontAwesomeIcon className="Canvas__card-help-icon" icon={faQuestionCircle} onClick={() => this.toggleHelpModal(true, columnValue.title, columnValue.helpText)} />
                                                </Card.Header>
                                                <Card.Body className="p-0 Canvas__card-body">
                                                    <Card.Text>
                                                        <CanvasCardContent cardContent={ columnValue.value } changeContent={this.onChangeCard}  cardOrder={columnValue.order}  cardTitle={columnValue.title} cardPlaceholder={columnValue.cardPlaceholder}/>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                                { columnValue.decisions ?
                                                    <div key={"Card-Decision-"+columnValue.order} className={"mt-2 mb-2 align-self-end"} style={{ marginLeft: "4px", marginRight: "4px", borderColor: columnValue.color, borderWidth: "2px" }}>
                                                        <div className="p-0 Canvas__card-decision">
                                                            {columnValue.decisions.map((decision, index) =>
                                                                decision.type === "SINGLE" ?
                                                                    <div className="Canvas__card-decision-actions">
                                                                        <div key={decision.id + "-" + index}
                                                                             className="d-flex justify-content-between Canvas__card-decision-actions-action">
                                                                            <div
                                                                                className="Canvas__card-decision-actions-action-title">{decision.title}</div>
                                                                            <ButtonGroup toggle
                                                                                         className="Canvas__card-decision-actions-action-options"
                                                                                         size={"sm"}>
                                                                                <ToggleButton
                                                                                    type="radio"
                                                                                    variant="outline-secondary"
                                                                                    name="radio"
                                                                                    value={decision.positiveButtonText}
                                                                                    checked={decision.value === 1}
                                                                                    onChange={() => this.checkButton(columnValue.id, decision.id, 1)}
                                                                                >
                                                                                    {decision.positiveButtonText}
                                                                                </ToggleButton>
                                                                                <ToggleButton
                                                                                    type="radio"
                                                                                    variant="outline-secondary"
                                                                                    name="radio"
                                                                                    value={decision.negativeButtonText}
                                                                                    checked={decision.value === 0}
                                                                                    onChange={() => this.checkButton(columnValue.id, decision.id, 0)}
                                                                                >
                                                                                    {decision.negativeButtonText}
                                                                                </ToggleButton>
                                                                            </ButtonGroup>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    decision.type === "MULTI" ?
                                                                        <div
                                                                            className="Canvas__card-decision-actions">
                                                                            <div key={decision.id + "-" + index}
                                                                                 className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                                <div
                                                                                    className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                                <div>
                                                                                    <Form
                                                                                        className="Canvas__card-decision-actions-action-options w-100"
                                                                                        inline>
                                                                                        {
                                                                                            decision.options.map((option, index) =>
                                                                                                <Form.Check
                                                                                                    label={option}
                                                                                                    key={decision.id + "-check-" + index}
                                                                                                    id={option}
                                                                                                    inline
                                                                                                    checked={decision.value.indexOf(option) > -1}
                                                                                                    onChange={(e) => this.changeOption(columnValue.id, decision.id, option, e.target.checked)}/>
                                                                                            )
                                                                                        }
                                                                                        {decision.hasOther ?
                                                                                            <div className="d-inline-flex">
                                                                                                <Form.Check
                                                                                                    label="Other: "
                                                                                                    id="Other"
                                                                                                    checked={decision.otherValue}
                                                                                                    onChange={(e) => this.changeOther(columnValue.id, decision.id, e.target.checked)}/>
                                                                                                <input
                                                                                                    className="Canvas__card-decision-actions-action-input"
                                                                                                    placeholder="Type here..."
                                                                                                    value={decision.otherTextValue}
                                                                                                    disabled={!decision.otherValue}
                                                                                                    onChange={(e) => this.changeOtherText(columnValue.id, decision.id, e.target.value)}/>
                                                                                            </div>
                                                                                            : null }
                                                                                    </Form>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div
                                                                            className="Canvas__card-decision-actions">
                                                                            <div key={decision.id + "-" + index}
                                                                                 className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                                <div
                                                                                    className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                                <TextareaAutosize className="Canvas__card-decision-actions-action-input"
                                                                                                  value={decision.value}
                                                                                                  placeholder="Type here..."
                                                                                                  onChange={(e) => this.changeDecisionText(columnValue.id, decision.id, e.target.value)}/>

                                                                               </div>
                                                                        </div>
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        )}
                                    </div>
                                    :
                                    value.column === "arrow-top" || value.column === "arrow-bottom" ?
                                        <div className={"d-flex justify-content-center w-100 column-" + value.column} key={value.column}>
                                            <FontAwesomeIcon style={{ color: value.color }} icon={faAngleDown} size="2x"
                                            />
                                        </div>
                                        :
                                        <div className={"d-flex flex-column text-left justify-content-start Canvas__w-33 p-1 column-" + value.column}>
                                            { this.state.data.filter((columnValue, index, self) =>
                                                columnValue.column === value.column
                                            ).map((columnValue, index) =>
                                                <>
                                                <Card key={"Card-"+columnValue.order} className={"mt-2 w-100 align-self-end Canvas__card Canvas__card-" + columnValue.state } style={{borderColor: columnValue.color, borderWidth: "2px" }}>
                                                    <Card.Header className="d-flex Canvas__card-header justify-content-between" style={{background: columnValue.color }}>
                                                        <div>{columnValue.order + ". " + columnValue.title}</div>
                                                        <FontAwesomeIcon className="Canvas__card-help-icon" icon={faQuestionCircle} onClick={() => this.toggleHelpModal(true, columnValue.title, columnValue.helpText)} />
                                                    </Card.Header>
                                                    <Card.Body className="p-0 Canvas__card-body">
                                                        <Card.Text>
                                                            <CanvasCardContent disabled={ columnValue.state === "ENABLED" ? false : true } cardContent={ columnValue.value } changeContent={this.onChangeCard} cardOrder={columnValue.order} cardPlaceholder={columnValue.cardPlaceholder}/>
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                                { columnValue.decisions ?
                                                <div key={"Card-Decision-"+columnValue.order} className={"mt-2 w-100 align-self-end"} style={{borderColor: columnValue.color, borderWidth: "2px" }}>
                                                    <div className="p-0 Canvas__card-decision">
                                                        {columnValue.decisions.map((decision, index) =>
                                                            decision.type === "SINGLE" ?
                                                                <div className="Canvas__card-decision-actions">
                                                                    <div key={decision.id + "-" + index}
                                                                         className="d-flex justify-content-between Canvas__card-decision-actions-action">
                                                                        <div
                                                                            className="Canvas__card-decision-actions-action-title">{decision.title}</div>
                                                                        <ButtonGroup toggle
                                                                                     className="Canvas__card-decision-actions-action-options"
                                                                                     size={"sm"}>
                                                                            <ToggleButton
                                                                                type="radio"
                                                                                variant="outline-secondary"
                                                                                name="radio"
                                                                                value={decision.positiveButtonText}
                                                                                checked={decision.value === 1}
                                                                                onChange={() => this.checkButton(columnValue.id, decision.id, 1)}
                                                                            >
                                                                                {decision.positiveButtonText}
                                                                            </ToggleButton>
                                                                            <ToggleButton
                                                                                type="radio"
                                                                                variant="outline-secondary"
                                                                                name="radio"
                                                                                value={decision.negativeButtonText}
                                                                                checked={decision.value === 0}
                                                                                onChange={() => this.checkButton(columnValue.id, decision.id, 0)}
                                                                            >
                                                                                {decision.negativeButtonText}
                                                                            </ToggleButton>
                                                                        </ButtonGroup>
                                                                    </div>
                                                                </div>
                                                                :
                                                                decision.type === "MULTI" ?
                                                                    <div
                                                                        className="Canvas__card-decision-actions">
                                                                        <div key={decision.id + "-" + index}
                                                                             className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                            <div
                                                                                className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                            <div>
                                                                                <Form
                                                                                    className="Canvas__card-decision-actions-action-options w-100"
                                                                                    inline>
                                                                                    {
                                                                                        decision.options.map((option, index) =>
                                                                                            <Form.Check
                                                                                                label={option}
                                                                                                key={decision.id + "-check-" + index}
                                                                                                id={option}
                                                                                                inline
                                                                                                checked={decision.value.indexOf(option) > -1}
                                                                                                onChange={(e) => this.changeOption(columnValue.id, decision.id, option, e.target.checked)}/>
                                                                                        )
                                                                                    }
                                                                                    {decision.hasOther ?
                                                                                    <div className="d-inline-flex">
                                                                                        <Form.Check
                                                                                            label="Other: "
                                                                                            id="Other"
                                                                                            checked={decision.otherValue}
                                                                                            onChange={(e) => this.changeOther(columnValue.id, decision.id, e.target.checked)}/>
                                                                                        <input
                                                                                            className="Canvas__card-decision-actions-action-input"
                                                                                            placeholder="Type here..."
                                                                                            value={decision.otherTextValue}
                                                                                            disabled={!decision.otherValue}
                                                                                            onChange={(e) => this.changeOtherText(columnValue.id, decision.id, e.target.value)}/>
                                                                                    </div>
                                                                                        : null }
                                                                                </Form>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        className="Canvas__card-decision-actions">
                                                                        <div key={decision.id + "-" + index}
                                                                             className="d-flex flex-column justify-content-between Canvas__card-decision-actions-action">
                                                                            <div
                                                                                className="Canvas__card-decision-actions-action-title w-100">{decision.title}</div>
                                                                            <TextareaAutosize className="Canvas__card-decision-actions-action-input"
                                                                                              value={decision.value}
                                                                                              placeholder="Type here..."
                                                                                              onChange={(e) => this.changeDecisionText(columnValue.id, decision.id, e.target.value)}/>
                                                                        </div>
                                                                    </div>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                                    :
                                                    null
                                                }
                                                </>
                                            )}
                                        </div>
                            )}
                    </div>
                </Container>
                <Modal show={this.state.showHelp} animation={false} backdrop={false} enforceFocus={false} className="HelpModal" onHide={() => this.toggleHelpModal(false, "", "")}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                            &nbsp;
                            Help
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{overflow: "auto"}}>
                        <h3>{this.state.chosenCardTitle}</h3>
                        <p>
                            <ReactMarkdown>
                                { this.state.chosenCardHelp }
                            </ReactMarkdown>
                        </p>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{overflow: "auto", whiteSpace: "pre-line"}}>
                        <p>
                            <ReactMarkdown>
                            {this.state.modalText}
                            </ReactMarkdown>
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showModal: false})}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="mt-5 pt-3 mb-3" style={{ borderTop: "1px solid #969696"}}>
                    <a href="https://hhlabc.de/impressum">Imprint</a>
                    &nbsp;|&nbsp;
                    <a href="https://hhlabc.de/datenschutz/">Privacy</a>
                </div>
            </div>
        );
    }

    toggleHelpModal(isVisisble, title, helpText) {
        this.setState({
            showHelp: isVisisble,
            chosenCardTitle: title,
            chosenCardHelp: helpText
        });
    }


    checkButton(boxId, decisionId, value) {
        let newData = this.state.data;
        let box =
        newData.filter((value) =>
           value.id === boxId
        )[0];

        let decision = box.decisions.filter(value =>
            value.id === decisionId)[0];

        decision.value = value;

        if(value === 0){
            this.setState({ data: newData, showModal: true, modalTitle: "Hint", modalText: decision.negativeModalText });
        }else {
            this.setState({ data: newData });
        }

        collaboration.pushData(wsData, this.state.data);
    }

    changeOption(questionId, decisionId, option, value) {
        let newData = this.state.data;
        let box =
            newData.filter((value) =>
                value.id === questionId
            )[0];

        let decision = box.decisions.filter(value =>
            value.id === decisionId)[0];


        if(!value) {
            let index = decision.value.indexOf(option);
            if(index > -1){
                decision.value.splice(index, 1);
            }
        }else {
            decision.value.push(option);
        }
        console.log(newData);

        this.setState({ data: newData });
        collaboration.pushData(wsData, this.state.data);
    }

    changeDecisionText(questionId, decisionId, value) {
        let newData = this.state.data;
        let box =
            newData.filter((value) =>
                value.id === questionId
            )[0];

        let decision = box.decisions.filter(value =>
            value.id === decisionId)[0];

        decision.value = value;

        this.setState({ data: newData });
        collaboration.pushData(wsData, this.state.data);
    }

    changeOther(questionId, decisionId, checked) {
        let newData = this.state.data;
        let box =
            newData.filter((value) =>
                value.id === questionId
            )[0];

        let decision = box.decisions.filter(value =>
            value.id === decisionId)[0];

        decision.otherValue = checked;
        this.setState({ data: newData });
        collaboration.pushData(wsData, this.state.data);
    }

    changeOtherText(questionId, decisionId, value) {
        let newData = this.state.data;
        let box =
            newData.filter((value) =>
                value.id === questionId
            )[0];

        let decision = box.decisions.filter(value =>
            value.id === decisionId)[0];

        decision.otherTextValue = value;
        this.setState({ data: newData });
        collaboration.pushData(wsData, this.state.data);
    }
}

export default withRouter(Canvas);